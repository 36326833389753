import React, { useState, useEffect } from "react";
import { db, storage, auth } from "../util/firebaseconfig";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import imageCompression from 'browser-image-compression';


const { collection, addDoc, getDocs } = require("firebase/firestore");
const { ref, uploadBytes, getDownloadURL } = require("firebase/storage");
const { onAuthStateChanged} = require("firebase/auth");

const NewPost = () => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
  });
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});
  const [username, setUsername] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userId, setUserId] = useState(null); 
  const [isUploading, setIsUploading] = useState(false); 

  const navigate = useNavigate();

  useEffect(() => {

    const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          setIsLoggedIn(true);
          setUsername(user.displayName);
          setUserId(user.uid);
          } else {
          setIsLoggedIn(false);
          setUserId(null);
          navigate("/login");

        }
      });
  
      return () => unsubscribe();
    }, []);
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let isValid = true;

    if (name === "title" || name === "description") {
      const regex = /\S/;
      if (!regex.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]:
            "Title and Description cannot be blank.",
        }));
        isValid = false;
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
      }
    } else {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
      }

    if (isValid) {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleImageChange = async(e) => {
    const files = Array.from(e.target.files);
    const newImages = [];
    const previews = [];
    const options = {
      maxSizeMB: 2.0,
      maxWidthOrHeight: 800,
      useWebWorker: true
    }

    for (const file of files) {
      try {
        const compressedFile = await imageCompression(file, options);
        newImages.push(compressedFile);
        previews.push(URL.createObjectURL(compressedFile));
      } catch(error) {
        setError(`Error uploading image ${file.name}: ${error.message}.`);
      }
    }
    setImages((prevImages) => [...prevImages, ...newImages]);
    setImagePreviews((prevPreviews) => [...prevPreviews, ...previews]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { title, description } = formData;

    const currentTime = Date.now();

    if (!title || !description) {
      setErrors({
        ...errors,
        title: !title ? "Title is required." : "",
        description: !description ? "Description is required." : "",
      });
      return;
    }
    try {
      setIsUploading(true); // Set uploading state to true
      const imageUrls = [];

      for (const image of images) {
        const imageRef = ref(storage, `images/${v4()} `);
        await uploadBytes(imageRef, image);
        const url = await getDownloadURL(imageRef);
        imageUrls.push(url);
      }

      await addDoc(collection(db, "posts"), {
        title: formData.title,
        description: formData.description,
        imageUrls: imageUrls,
        postTime: currentTime,
        authorName: username,
        userId: userId,
        repliesCount: 0,
      });

      setFormData({
        title: "",
        description: "",
        postTime: "",
      });
      setIsUploading(false); // Reset uploading state after completion
      navigate("/home");
    } catch (error) {
      console.error("Error adding post: ", error.message);
      setIsUploading(false); // Reset uploading state after completion
    }
  };

  const handleDeleteImage = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);

    const newPreviews = [...imagePreviews];
    newPreviews.splice(index, 1);
    setImagePreviews(newPreviews);
  };

  return (
    <div className="container">
      <form 
        onSubmit={handleSubmit}
      >
        <div className="form-group">
          <p>{formData.postTime}</p>
        </div>
        <div className="form-group">
          <label htmlFor="title">Title:</label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
          />
        </div>
        {errors.title && <p className="error-message">{errors.title}</p>}
        <div className="form-group">
          <label htmlFor="description">Description:</label>
          <textarea
            cols="120"
            rows="5"
            type="text"
            id="description"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
          />
        </div>
        {errors.description && (
          <p className="error-message">{errors.description}</p>
        )}

        <div className="form-group">
          <label htmlFor="image">Images:</label>
          <input
            type="file"
            id="image"
            name="image"
            multiple
            onChange={handleImageChange}
          />
          <div className="row">
            {imagePreviews.map((preview, index) => (
              <div key={index} className="image-preview">
                <img src={preview} alt={`Image ${index}`} className="img-thumbnail" />
                <button type="button" className="btn btn-sm btn-danger" onClick={() => handleDeleteImage(index)}>
                  Delete
                </button>
              </div>
            ))}
          </div>
          {error && <p className="error-message">{error}</p>}
        </div>
        {errors.submit && <p className="error-message">{errors.submit}</p>}
        {isLoggedIn ? (
          <button 
            className="btn btn-lg btn-primary btn-block mt-3 w-100" 
            type="submit"
            disabled={isUploading} // Disable the button while uploading
          >
            {isUploading ? "Uploading..." : "Add Post"} 
          </button>
        ) : (
          <button onClick={() => navigate("/")}>Login</button>
        )}
      </form>
    </div>
  );
};

export default NewPost;
